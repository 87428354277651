import React from "react";

const passwordLevels = {
  1: "Fraca",
  2: "Média",
  3: "Forte",
  4: "Segura",
};

export default function PasswordLevel({ level }) {
  if (!level) return null;

  const getBarClass = (lvl) => {
    if (lvl <= level) {
      switch (level) {
        case 1:
          return "bg-red-600";
        case 2:
          return "bg-orange-600";
        case 3:
          return "bg-blue-600";
        case 4:
          return "bg-green-600";
        default:
          return "";
      }
    }
    return "bg-light-200";
  };

  const getTextClass = () => {
    switch (level) {
      case 1:
        return "text-red-600";
      case 2:
        return "text-orange-600";
      case 3:
        return "text-blue-600";
      case 4:
        return "text-green-600";
      default:
        return "";
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="w-full flex gap-2">
        {[1, 2, 3, 4].map((lvl) => (
          <span
            key={lvl}
            className={`h-[3px] w-full rounded-full ${getBarClass(lvl)}`}
          ></span>
        ))}
      </div>
      <div className="flex justify-between">
        <span className="font-poppins text-p6 text-light-700">
          Força da sua senha
        </span>
        <span
          className={`font-poppins text-p6 font-semibold ${getTextClass()}`}
        >
          {passwordLevels[level]}
        </span>
      </div>
    </div>
  );
}
