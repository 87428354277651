import React, { useEffect, useState } from "react";

import "swiper/css";
import "swiper/css/pagination";

import { useApi } from "../hooks/useApi";
import { Navbar } from "../components/navbar";

import { FullscreenLoading } from "../components/loading-fullscreen";
import { ProjectsList } from "../components/projects-list";

export const Projects = () => {
  const { loading, userData} = useApi();
  const [user, setUser] = useState({ name: "Cliente" });

  useEffect(() => {
    (async () => {
      const res = await userData();
      setUser(res);
    })();
  }, []);

  if (loading["user-data"] || loading["home-data"])
    return <FullscreenLoading state={true} />;

  return (
    <div className="flex flex-col items-center min-h-screen bg-dark-150">
      <Navbar user={user} />
      <main className="flex justify-center w-full px-4 transition-all">
        <section className="flex flex-col w-full gap-8 my-4 transition-all xl:mx-8 xl:max-w-screen-xl 2xl:max-w-screen-2xl">
          <div className="header">
            <h1 className="text-lg font-medium font-sora text-light-0">
              Veja os projetos que você criou!
            </h1>
          </div>

          <div className="flex flex-wrap gap-8">
            <div className="w-full">
              <div className="flex flex-col gap-8">
                <ProjectsList />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
