import React, { createContext, useContext, useState } from "react";

// Defina o contexto
const AccordionContext = createContext();

// Componente AccordionProvider
export const AccordionProvider = ({ children }) => {
  const [state, setState] = useState({
    count: 0,
    active: null,
  });

  return (
    <AccordionContext.Provider value={{ state, setState }}>
      {children}
    </AccordionContext.Provider>
  );
};

// Componente Accordion
export const Accordion = ({ children }) => {
  return <ul className="w-full divide-y divide-light-0/5">{children}</ul>;
};

// Hook personalizado para acessar o contexto
export const useAccordion = () => useContext(AccordionContext);
