export const ShieldCheck = ({ size = 48, ...rest }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 8.00012L23.6314 5.06923C23.875 5.02355 24.125 5.02355 24.3686 5.06923L40 8.00012V29.9034C40 33.6961 38.0462 37.2213 34.83 39.2314L24.53 45.6689C24.2057 45.8715 23.7943 45.8715 23.47 45.6689L13.17 39.2314C9.95382 37.2213 8 33.6961 8 29.9034L8 8.00012Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.35 29.5C21.8708 29.5 21.3916 29.3083 21.0083 28.925L17.175 25.0917C16.4083 24.325 16.4083 23.175 17.175 22.4083C17.9416 21.6417 19.1875 21.6417 19.8583 22.4083L22.35 24.9L28.675 18.575C29.4416 17.8083 30.5916 17.8083 31.3583 18.575C32.125 19.3417 32.125 20.4917 31.3583 21.2583L23.6916 28.925C23.3083 29.3083 22.8291 29.5 22.35 29.5Z"
    />
  </svg>
);
