import { useEffect, useState } from "react";
import { EyeAlt } from "../components/icons/dark/eye-alt";
import { Eye } from "../components/icons/dark/eye";
import { Close2 } from "../components/icons/dark/close-2";

export const TextField = ({
  label,
  id,
  errorMessage = "",
  type = "text",
  required = false,
  autocomplete = "off",
  value,
  onChange,
}) => {
  const [inputType, setInputType] = useState("");
  // const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setInputType(type);
    // setShowPassword(type === "password" && inputType === "password");
  }, [type]);

  function toggleType() {
    setInputType((prev) => (prev === "text" ? "password" : "text"));
    // setShowPassword((prev) => !prev);
  }

  return (
    <div className="relative">
      <input
        spellCheck="false"
        type={inputType}
        className={`peer h-14 w-full rounded-tiny p-xxxs font-poppins text-p5 bg-transparent text-dark-200 outline outline-1 -outline-offset-1 outline-light-600 duration-500 ease-in-out placeholder:text-transparent focus:!outline-primary-600 ${
          errorMessage ? "!outline-red-600" : ""
        } ${type === "password" ? "pr-12" : ""}`}
        id={id}
        placeholder={label}
        value={value}
        onChange={onChange}
        required={required}
        autoComplete={autocomplete}
      />
      <label
        htmlFor={id}
        className={`absolute left-3 -translate-y-[9px] cursor-text rounded-tiny bg-white px-quarck font-poppins text-p6 font-medium text-light-600 duration-500 ease-in-out peer-placeholder-shown:translate-y-4 peer-placeholder-shown:text-p5 peer-placeholder-shown:font-normal peer-placeholder-shown:text-light-600 peer-focus:-translate-y-[9px] peer-focus:text-p6 peer-focus:font-medium peer-focus:text-primary-600 ${
          errorMessage ? "text-red-600" : ""
        }`}
      >
        {label}
      </label>
      {type === "password" && !errorMessage && (
        <button
          className="absolute right-2 top-1/2 flex -translate-y-1/2 items-center justify-center fill-light-600 p-2 peer-focus:fill-primary-600"
          type="button"
          onClick={toggleType}
        >
          {inputType === "password" ? (
            <EyeAlt className="fill-inherit" />
          ) : (
            <Eye className="fill-inherit" />
          )}
        </button>
      )}
      {errorMessage && (
        <span className="absolute right-4 top-[18px] flex aspect-square w-5 items-center justify-center rounded-full bg-red-600">
          <Close2 className="fill-light-0" size={8} />
        </span>
      )}
      {errorMessage && (
        <p className="p-quarck pb-0 font-poppins text-p6 text-red-600">
          {errorMessage}
        </p>
      )}
    </div>
  );
};
