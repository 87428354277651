export const Eye = ({ size = 20, ...rest }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3332 8C13.3332 9.84095 11.8408 11.3333 9.9999 11.3333C8.15895 11.3333 6.66656 9.84095 6.66656 8C6.66656 6.15905 8.15895 4.66667 9.9999 4.66667C11.8408 4.66667 13.3332 6.15905 13.3332 8ZM11.6666 8C11.6666 8.92047 10.9204 9.66667 9.9999 9.66667C9.07942 9.66667 8.33323 8.92047 8.33323 8C8.33323 7.07953 9.07942 6.33333 9.9999 6.33333C10.9204 6.33333 11.6666 7.07953 11.6666 8Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.9999 0.5C14.6595 0.5 18.5747 3.68693 19.6849 8C18.5747 12.3131 14.6595 15.5 9.9999 15.5C5.3403 15.5 1.42505 12.3131 0.314941 8C1.42505 3.68693 5.3403 0.5 9.9999 0.5ZM9.9999 13.8333C6.27026 13.8333 3.10933 11.3818 2.04759 8C3.10933 4.61822 6.27026 2.16667 9.9999 2.16667C13.7295 2.16667 16.8905 4.61822 17.9522 8C16.8905 11.3818 13.7295 13.8333 9.9999 13.8333Z"
    />
  </svg>
);
