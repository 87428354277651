import localforage from "localforage";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FullscreenLoading } from "../components/loading-fullscreen";

export const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    localforage.getItem("auth").then((auth) => {
      setIsLoading(false);
      if (!auth) return navigate("/auth/sign-in");
    });
  }, []);

  if (isLoading) return <FullscreenLoading state={isLoading} />;
  return children;
};
