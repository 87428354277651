import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Spinner } from "@blueprintjs/core";

import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel, DEFAULT_SECTIONS } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import { Tooltip } from "polotno/canvas/tooltip";
import { PagesTimeline } from "polotno/pages-timeline";
import { setTranslations } from "polotno/config";

import { loadFile } from "../file";

import { QrSection } from "../sections/qr-section";
import { QuotesSection } from "../sections/quotes-section";
import { IconsSection } from "../sections/icons-section";
import { ShapesSection } from "../sections/shapes-section";
import { StableDiffusionSection } from "../sections/stable-diffusion-section";
import { MyDesignsSection } from "../sections/my-designs-section";

import { AIWriteMenu } from "../ai-text";
// import { VideosSection } from '../sections/video-section';
import { UploadSection } from "../sections/upload-section";

import { useProject } from "../project";

import { ImageRemoveBackground } from "../background-remover";

import "../assets/styles/global.css";
import fr from "../translations/fr";
import en from "../translations/en";
import id from "../translations/id";
import ru from "../translations/ru";
import ptBr from "../translations/pt-br";

// import Topbar from "../topbar/topbar";
import { Navbar } from "../components/navbar";
import { useApi } from "../hooks/useApi";
import { FullscreenLoading } from "../components/loading-fullscreen";
import { MyTemplatesSection } from "../sections/templates-section";
import { useLocation } from "react-router-dom";

// load default translations
setTranslations(en);

// replace elements section with just shapes
DEFAULT_SECTIONS.splice(3, 1, ShapesSection);
// replace templates section
const templatesIndex = DEFAULT_SECTIONS.findIndex(
  (item) => item.name === "templates"
);
DEFAULT_SECTIONS.splice(templatesIndex, 1, MyTemplatesSection);
// replace default upload section
const uploadIndex = DEFAULT_SECTIONS.findIndex(
  (item) => item.name === "upload"
);
DEFAULT_SECTIONS.splice(uploadIndex, 1, UploadSection);

// add icons
DEFAULT_SECTIONS.splice(3, 0, IconsSection);
// add two more sections
DEFAULT_SECTIONS.push(QuotesSection, QrSection);
// DEFAULT_SECTIONS.unshift(UploadSection);
// DEFAULT_SECTIONS.unshift(MyTemplatesSection);
DEFAULT_SECTIONS.unshift(MyDesignsSection);

DEFAULT_SECTIONS.push(StableDiffusionSection);
// DEFAULT_SECTIONS.push(VideosSection);

const isStandalone = () => {
  return (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone
  );
};

const getOffsetHeight = () => {
  let safeAreaInsetBottom = 0;

  if (isStandalone()) {
    // Try to get the safe area inset using env() variables
    const safeAreaInsetBottomString = getComputedStyle(
      document.documentElement
    ).getPropertyValue("env(safe-area-inset-bottom)");
    if (safeAreaInsetBottomString) {
      safeAreaInsetBottom = parseFloat(safeAreaInsetBottomString);
    }

    // Fallback values for specific devices if env() is not supported
    if (!safeAreaInsetBottom) {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPhone|iPad|iPod/i.test(userAgent) && !window.MSStream) {
        // This is an approximation; you might need to adjust this value based on testing
        safeAreaInsetBottom = 20; // Example fallback value for iPhone
      }
    }
  }

  return window.innerHeight - safeAreaInsetBottom;
};

const useHeight = () => {
  const [height, setHeight] = React.useState(getOffsetHeight());
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(getOffsetHeight());
    });
  }, []);
  return height;
};

const App = observer(({ store }) => {
  const project = useProject();
  const height = useHeight();
  const [user, setUser] = React.useState({ name: "Cliente" });
  const { loading, userData } = useApi();
  const [loadingApp, setLoadingApp] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (project.language.startsWith("fr")) {
      setTranslations(fr, { validate: true });
    } else if (project.language.startsWith("id")) {
      setTranslations(id, { validate: true });
    } else if (project.language.startsWith("ru")) {
      setTranslations(ru, { validate: true });
    } else if (project.language.startsWith("pt")) {
      setTranslations(ptBr, { validate: true });
    } else {
      setTranslations(en, { validate: true });
    }
  }, [project.language]);

  React.useEffect(() => {
    setLoadingApp(true);

    const params = new URLSearchParams(location.search);
    const templateId = params.get("template_id");
    
    (async () => {
      const res = await userData();
      setUser(res);
    })();

    const initializeProject = async () => {
      if (templateId) {
        await project.firstLoad();
        await project.loadByTemplateId({ template_id: templateId });
      } else {
        await project.firstLoad();
      }
    };

    initializeProject();
    setLoadingApp(false);
    console.log(DEFAULT_SECTIONS);
  }, []);

  const handleDrop = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // skip the case if we dropped DOM element from side panel
    // in that case Safari will have more data in "items"
    if (ev.dataTransfer.files.length !== ev.dataTransfer.items.length) {
      return;
    }
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < ev.dataTransfer.files.length; i++) {
      loadFile(ev.dataTransfer.files[i], store);
    }
  };

  if (loadingApp) return <FullscreenLoading state={true} />;
  return (
    <div
      style={{
        width: "100vw",
        height: height + "px",
        display: "flex",
        flexDirection: "column",
      }}
      onDrop={handleDrop}
    >
      <Navbar store={store} user={user} editor />

      {/* <Topbar store={store} /> */}
      <div style={{ height: "calc(100% - 81px)" }}>
        <PolotnoContainer className="polotno-app-container">
          <SidePanelWrap>
            <SidePanel store={store} sections={DEFAULT_SECTIONS} />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar
              store={store}
              components={{
                ImageRemoveBackground,
                TextAIWrite: AIWriteMenu,
              }}
            />
            <Workspace
              store={store}
              components={{ Tooltip, TextAIWrite: AIWriteMenu }}
            />
            <ZoomButtons store={store} />
            <PagesTimeline store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </div>
      {project.status === "loading" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
            }}
          >
            <Spinner />
          </div>
        </div>
      )}
    </div>
  );
});

export default App;
