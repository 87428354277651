export const MailOppened = ({ size = 48, ...rest }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      opacity="0.3"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 5C10 4.44772 10.4477 4 11 4H37C37.5523 4 38 4.44772 38 5V25C38 25.5523 37.5523 26 37 26H11C10.4477 26 10 25.5523 10 25V5ZM14 11C14 10.4477 14.4477 10 15 10H27C27.5523 10 28 10.4477 28 11C28 11.5523 27.5523 12 27 12H15C14.4477 12 14 11.5523 14 11ZM15 14C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16H21C21.5523 16 22 15.5523 22 15C22 14.4477 21.5523 14 21 14H15Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 12.9779C6 12.57 6.46208 12.3337 6.79275 12.5725L24 25L41.2073 12.5725C41.5379 12.3337 42 12.57 42 12.9779V36C42 37.1046 41.1046 38 40 38H8C6.89543 38 6 37.1046 6 36V12.9779Z"
    />
  </svg>
);
