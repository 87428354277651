import React, { useState, useContext, useEffect } from "react";
import { Collapse } from "react-collapse";

import { useAccordion } from "./accordion"; // O hook useAccordion que criamos anteriormente
import { BellRing, Library, CircleAlert, Mail } from "lucide-react";
import { light, primary, orange } from "../assets/braip-theme/color";

// Propriedades esperadas pelo componente
export const AccordionItem = ({ title, type, html }) => {
  const [index, setIndex] = useState(0);
  const { state, setState } = useAccordion();

  const visible = index === state.active;

  const open = () => {
    setState({
      ...state,
      active: visible ? null : index,
    });
  };

  useEffect(() => {
    setIndex(state.count);
    setState((prevState) => ({
      ...prevState,
      count: prevState.count + 1,
    }));
  }, [state.count, setState]);

  return (
    <li className="w-full">
      <button
        className={`w-full p-4 flex justify-start items-center gap-2 hover:bg-primary-100/5 transition ${
          visible ? "accordion__trigger_active" : ""
        }`}
        onClick={open}
      >
        {type === "alert" ? (
          <BellRing size={20} className="text-red-600 min-w-[22px]" />
        ) : type === "info" ? (
          <Library size={20} fill={primary[600]} className="min-w-[22px]" />
        ) : type === "warning" ? (
          <CircleAlert size={20} fill={orange[600]} className="min-w-[22px]" />
        ) : (
          <Mail size={20} fill={light[0]} className="min-w-[22px]" />
        )}
        <span className="text-light-0 text-left text-sm font-medium">
          {title}
        </span>
      </button>
      <Collapse isOpened={visible}>
        <div
          className="w-full px-4 pt-2 pb-4 text-sm text-light-0 text-justify"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </Collapse>
    </li>
  );
};
