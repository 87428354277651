import React from "react";
import "./loading-fullscreen.css"; // Certifique-se de colocar o CSS aqui
import LogoWhite from "../../assets/img/logos/braip-creative-horizontal-branco.svg";

export const FullscreenLoading = ({ state }) => {
  return (
    <div
      className={`fullscreen-loading z-50 ${state ? "fade-in" : "fade-out"}`}
    >
      <div className={`content-center ${state ? "actived" : "closed"}`}>
        <img src={LogoWhite} alt="Logotipo" className="w-44" />
        <span className="load"></span>
      </div>

      <div className={`loader-section ${state ? "" : "closed"}`}></div>
    </div>
  );
};
