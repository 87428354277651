import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "../../components/text-field";
import { Button } from "../../components/button";

import { useApi } from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import localforage from "localforage";

export const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [isDisabled] = useState(false);

  const { login, loading } = useApi();
  const { setAuth } = useAuth();

  const validForm = () => {
    const emailRegex = /[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    if (!email) {
      setErrorEmail("O campo é obrigatório.");
    } else if (!emailRegex.test(email)) {
      setErrorEmail("O e-mail informado é inválido.");
    }
    if (!password) {
      setErrorPassword("O campo é obrigatório.");
    } else {
      setErrorPassword("");
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    validForm();
    setErrorEmail("");
    setErrorPassword("");

    // if (errorEmail || errorPassword) return;
    if (!email || !password) return;

    login({ email: email.trim(), password })
      .then(async (res) => {
        if (res?.["access_token"]) {
          await localforage.setItem("auth", res);
          setAuth({
            accessToken: res.access_token,
            tokenType: res.token_type,
            roles: res.roles,
          });
          navigate("/");
        }
      })
      .catch((error) => {
        localforage.removeItem("auth");
        if (error.response?.status === 401)
          setErrorEmail("Email ou senha incorretos.");
      });
  };

  return (
    <div className="flex min-h-full flex-col bg-primary-600 bg-braip-login bg-cover bg-top bg-no-repeat">
      <main className="flex min-w-fit flex-1 flex-col items-center justify-center">
        <form
          className="mx-4 mt-xxl flex max-w-md flex-col gap-12 rounded-sm bg-white p-10"
          onSubmit={handleSignIn}
        >
          <div className="flex flex-col gap-14">
            <div className="flex items-center justify-between">
              <img
                className="w-36"
                src="/logos/braip-creative-horizontal-roxo.svg"
                alt="Logo do Braip Creative"
              />
            </div>

            <div className="flex flex-col gap-nano">
              <h1 className="text-dark-100 text-h7 font-sora font-semibold">
                Acesse sua conta
              </h1>
              <p className="text-light-700 text-p4 text-ellipsis">
                Insira seu e-mail e senha para realizar o login.
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-xs">
            <div className="flex flex-col gap-xxs">
              <div className="flex flex-col gap-xxxs">
                <TextField
                  label="E-mail"
                  id="email"
                  type="text"
                  value={email}
                  errorMessage={errorEmail}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrorEmail("");
                  }}
                />
                <TextField
                  label="Senha"
                  id="password"
                  type="password"
                  value={password}
                  errorMessage={errorPassword}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setErrorPassword("");
                  }}
                />
              </div>
              <div className="flex justify-end">
                <Link
                  className="text-right text-p5 font-medium !text-light-700 hover:!text-light-700 hover:!no-underline"
                  to="/forgot-password"
                >
                  Esqueceu a senha?
                </Link>
              </div>
            </div>
            <div className="flex flex-col items-center gap-xs">
              <Button
                text="Acessar"
                type="submit"
                className="text-center"
                size="large"
                semibold
                full
                disabled={isDisabled}
                loading={loading["login"]}
              />
              <p className="text-p5 font-normal text-dark-1100">
                Não tem acesso?{" "}
                <Link
                  to="/auth/sign-up"
                  className="font-medium !text-primary-600 hover:!text-primary-600 underline"
                >
                  Crie uma conta!
                </Link>
              </p>
            </div>
          </div>
        </form>
      </main>
      <footer className="flex w-full justify-center">
        <p className="mx-4 my-xs w-36 sm:w-full text-center text-p6 !text-light-0/40">
          © Braip 2024. Todos os direitos reservados
        </p>
      </footer>
    </div>
  );
};
