export const Close2 = ({ size = 20, ...rest }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M1.59362 1.00473C1.4309 0.842011 1.16708 0.842011 1.00436 1.00473C0.841645 1.16745 0.841645 1.43127 1.00436 1.59398L3.4105 4.00012L1.00439 6.40624C0.84167 6.56896 0.841669 6.83278 1.00439 6.99549C1.16711 7.15821 1.43093 7.15821 1.59364 6.99549L3.99976 4.58938L6.40587 6.99549C6.56859 7.15821 6.83241 7.15821 6.99513 6.99549C7.15785 6.83278 7.15785 6.56896 6.99513 6.40624L4.58901 4.00012L6.99515 1.59398C7.15787 1.43127 7.15787 1.16745 6.99515 1.00473C6.83244 0.842011 6.56862 0.842011 6.4059 1.00473L3.99976 3.41087L1.59362 1.00473Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.41684 1.18151C1.35175 1.11642 1.24623 1.11642 1.18114 1.18151C1.11605 1.24659 1.11605 1.35212 1.18114 1.41721L3.76406 4.00012L1.18116 6.58302C1.11608 6.6481 1.11608 6.75363 1.18116 6.81872C1.24625 6.88381 1.35178 6.88381 1.41687 6.81872L3.99976 4.23583L6.58265 6.81872C6.64774 6.88381 6.75326 6.88381 6.81835 6.81872C6.88344 6.75363 6.88344 6.6481 6.81835 6.58302L4.23546 4.00012L6.81838 1.41721C6.88346 1.35212 6.88346 1.24659 6.81838 1.18151C6.75329 1.11642 6.64776 1.11642 6.58267 1.18151L3.99976 3.76442L1.41684 1.18151ZM0.827586 0.827953C1.08794 0.567603 1.51005 0.567603 1.7704 0.827953L3.99976 3.05732L6.22912 0.827953C6.48947 0.567603 6.91158 0.567603 7.17193 0.827953C7.43228 1.0883 7.43228 1.51041 7.17193 1.77076L4.94257 4.00012L7.17191 6.22946C7.43226 6.48981 7.43226 6.91192 7.17191 7.17227C6.91156 7.43262 6.48945 7.43262 6.2291 7.17227L3.99976 4.94293L1.77042 7.17227C1.51007 7.43262 1.08796 7.43262 0.827611 7.17227C0.567262 6.91192 0.567262 6.48981 0.827611 6.22946L3.05695 4.00012L0.827586 1.77076C0.567237 1.51041 0.567237 1.0883 0.827586 0.827953Z"
    />
  </svg>
);
