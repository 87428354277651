import { Button, Position, Menu, Popover } from "@blueprintjs/core";
import localforage from "localforage";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";

export const SaveButton = () => {
  const { auth, setAuth } = useAuth();
  const [isDesigner, setIsDesigner] = useState(false);

  function handleSave(mode) {
    window.project.requestSave(mode);
  }

  useEffect(() => {
    localforage.getItem("auth").then((res) => {
      setAuth(res);
      setIsDesigner(
        () => res.roles?.includes("designer") || res.roles?.includes("admin")
      );
    });
  }, []);

  if (!isDesigner) {
    return (
      <Button
        onClick={() => handleSave("project")}
        icon="cloud-upload"
        text="Salvar"
        intent="success"
        // loading={saving}
      />
    );
  }
  return (
    <Popover
      content={
        <Menu>
          <li className="bp5-menu-header" style={{ marginBottom: "12px" }}>
            <h6 className="bp5-heading">Tipo de projeto</h6>
          </li>

          <li
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <Button
              fill
              intent="none"
              // loading={saving}
              onClick={() => handleSave("template")}
            >
              Publicar como modelo
            </Button>
            <Button
              fill
              intent="primary"
              // loading={saving}
              onClick={() => handleSave("project")}
            >
              Salvar como design
            </Button>
          </li>
        </Menu>
      }
      position={Position.BOTTOM_RIGHT}
    >
      <Button
        icon="cloud-upload"
        text="Salvar"
        intent="success"
        // loading={saving}
      />
    </Popover>
  );
};
