import React, { useEffect, useState } from "react";
import { useApi } from "../hooks/useApi";
import { Navbar } from "../components/navbar";
import { TutorialLink } from "../components/tutorial-link";

export const Tutorials = () => {
  const { getTutorials, userData, loading } = useApi();
  const [tutorials, setTutorials] = useState([]);
  const [user, setUser] = useState({ name: "Cliente" });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tutorialsData = await getTutorials();
        setTutorials(tutorialsData);

        const userDataResponse = await userData();
        setUser(userDataResponse);
      } catch (error) {
        console.error("Erro ao carregar dados:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col items-center min-h-screen bg-dark-150">
      <Navbar user={user} />
      <main className="flex justify-center w-full px-4 transition-all">
        {tutorials.length > 0 ? (
          <section className="flex flex-col w-full lg:w-9/12 gap-8 my-4 transition-all xl:mx-8 xl:max-w-screen-xl 2xl:max-w-screen-2xl">
            <div className="mb-2">
              <h3 className="p-4 text-base font-medium font-sora text-light-0">
                Primeiros passos
              </h3>
              <hr width="80" className="mt-1 border-t-1 border-primary-600" />
            </div>
            <ul className="tutorial">
              {tutorials
                .sort((a, b) => a.priority - b.priority)
                .map((tutorial) => (
                  <TutorialLink
                    key={tutorial.id}
                    title={tutorial.title}
                    link={tutorial.link}
                  />
                ))}
            </ul>
          </section>
        ) : (
          <div className="p-4 text-center rounded-md bg-dark-250 text-light-500">
            Nenhum tutorial encontrado.
          </div>
        )}
      </main>
    </div>
  );
};

export default Tutorials;
