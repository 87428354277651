import React from "react";
import { Library } from "lucide-react";
import { primary } from "../assets/braip-theme/color";

export const TutorialLink = ({ title, link }) => {
  return (
    <li className="w-full">
      <a
        className="w-full p-4 flex justify-start items-center gap-2 cursor-pointer hover:bg-light-0/5 transition"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Library fill={primary[600]} size={20} className="min-w-[22px]" />
        <span className="text-light-0 text-sm text-left">{title}</span>
      </a>
    </li>
  );
};
